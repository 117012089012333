import { RoleString } from '@zupr/types/user'
import { getFreshToken, getRefreshToken, refresh } from './auth'

import parseBody from './body'
import { getHost } from './host'
let role: RoleString | undefined

let defaultHeaders = {
    Accept: 'application/json',
}

// add zupr host (only if needed)
export const addHost = (url) => {
    if (!url) throw new Error('Url expected for addHost')
    if (typeof url !== 'string') {
        console.error({ url }, typeof url)
        throw new Error('String expected for addHost')
    }
    if (url.startsWith('http')) return url
    const host = getHost()

    if (!url.startsWith(host)) return `${host}/${url}`
    return url
}

export const get = (url: string, options?: Record<string, any>) =>
    call({ method: 'GET', url, ...(options || {}) })
export const post = (url, body) => call({ method: 'POST', url, body })
export const put = (url, body) => call({ method: 'PUT', url, body })
export const patch = (url, body) => call({ method: 'PATCH', url, body })
export const options = (url) => call({ method: 'OPTIONS', url })

export const getHeaders = async (headers) => {
    headers = {
        ...defaultHeaders,
        ...headers,
    }

    if (role) {
        headers.role = role
    }

    const accessToken = await getFreshToken()

    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`
    }

    return headers
}

interface CallOptions {
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'OPTIONS' | 'DELETE'
    url: string
    body?: any
    headers?: Record<string, any>
    cache?: never
}

export const call = async ({
    method,
    url,
    body,
    headers,
    cache,
}: CallOptions) => {
    try {
        url = addHost(url)
        body = parseBody(body)
        headers = await getHeaders(headers)

        if (role) {
            headers.role = role
        }

        if (!(body instanceof FormData)) {
            headers['Content-Type'] = 'application/json'
        }

        const response = await fetch(url, {
            method: method || 'GET',
            headers,
            body,
            cache,
        })

        // when the body is empty
        if (response.status === 204) {
            return true
        }

        // Check if status is is ok
        if (response.status >= 100 && response.status < 300) {
            // parse the json ourselves
            // sometimes bodys are still empty
            const bodyText = await response.text()
            if (bodyText) return JSON.parse(bodyText)
            return true
        }

        throw response
    } catch (response) {
        const refreshToken = getRefreshToken()

        // if unauthorized
        if (response.status === 401 && refreshToken) {
            try {
                await refresh(refreshToken)
                return call({ method, url, body, headers, cache })
            } catch (error) {
                throw error
            }
        }

        // unsuccesful
        throw response
    }
}

export { role }

export const setRole = (r?: RoleString) => {
    role = r
}
